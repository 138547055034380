<template>
  <div>
    <Header :title="'订单详情'" :back="true" :complete="'home'"/>
    <div class="success-box">
      <div class="flex-box box-border">
        <div class="right-box">
          <div v-if="type=='transfer'||type=='withdraw'" style="text-align:center;padding:2rem 0px">
            <div style="font-size:2rem;color: #d10921;">${{data?data.money:'-'|formatFloat}}</div>
            <div style="font-size:.9rem">{{data?data.status:'-'}}</div>
          </div>
          <!-- 提现 -->
          <div v-if="type=='withdraw'">
            <div class="flex-box">
              <div>收款银行</div>
              <div class="theme-color text-t">{{data?data.bankname:'-'}}</div>
            </div>
            <div class="flex-box">
              <div>收款账号</div>
              <div class="theme-color text-t">{{data?(data.account):'-'}}</div>
            </div>
            <div class="flex-box">
              <div>申请时间</div>
              <div class="theme-color text-t">{{data?(data.addtime):'-'}}</div>
            </div>
            <div class="flex-box">
              <div>订单号</div>
              <div class="theme-color text-t">{{data?data.code:'-'}}</div>
            </div>
            
          </div>
          <div v-if="type=='transfer'">
            <div class="flex-box">
              <div>转出金额</div>
              <div class="theme-color text-t">${{data?data.money:'-'|formatFloat}}</div>
            </div>
            <div class="flex-box">
              <div>申请时间</div>
              <div class="theme-color text-t">{{data?formatDate(data.time):'-'}}</div>
            </div>
            <div class="flex-box">
              <div>订单号</div>
              <div class="theme-color text-t">{{data?data.code:'-'}}</div>
            </div>
            <el-divider></el-divider>
            <div class="flex-box">
              <div>收款方ID</div>
              <div class="theme-color text-t">{{data?data.usercode:'-'}}</div>
            </div>
            <div class="flex-box">
              <div>收款方姓名</div>
              <div class="theme-color text-t">{{data?data.name:'-'}}</div>
            </div>
            <div class="flex-box">
              <div>与对方关系</div>
              <div class="theme-color text-t">{{data?data.relation:'-'}}</div>
            </div>
          </div>
          <div v-if="type=='in_installment'||type=='out_loan'">
            <span class='tj'>{{type|filterStatus}}</span>
            <div class="theme-color right-title">{{data?data.title:'-'}}</div>
            <div class="sub">订单号：{{key}}</div>
          </div>
          
          
          <div v-if="type=='in_installment'">
            <div class="flex-box top-row">
              <div>预约买入金额</div>
              <div class="theme-color text-t">${{data?data.money:'-'|formatFloat }}</div>
            </div>
            <div class="flex-box">
              <div>预期年化收益率</div>
              <div class="theme-color text-t">{{data?data.rate:'-'|formatFloat}}%</div>
            </div>
          </div>
          
          <div v-if="type=='out_loan'">
            <div class="flex-box">
              <div>提前转出金额</div>
              <div class="theme-color text-t">${{data?data.money:'-'|formatFloat}}</div>
            </div>
            <div class="flex-box">
              <div>手续费(1.5%)</div>
              <div class="theme-color text-t">${{data?data.handlingfee:'-'|formatFloat}}</div>
            </div>
            <div class="flex-box">
              <div>到账金额</div>
              <div class="theme-color text-t">${{data?data.cash:'-'|formatFloat}}</div>
            </div>
          </div>
          
        </div>
      </div>
      <div class="schedule-box box-border">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.confirm?'el-icon-check':''"
            :type="activity.type"
            :color="activity.confirm?'#002D91':''"
            :size="'large'"
            :timestamp="formatDate(activity.time)">
            {{activity.comment}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  data(){
    return{
      data:null,
      key:this.$route.query.key,
      type:"",
      activities: []
    }
  },
  filters:{
    filterStatus(type){
      const statusDay ={
        in_installment:'买入',
        out_loan:'转出',
        transfer:'转账',
        withdraw:'提现'
      }
      return statusDay[type]
    }
  },
  //    
  //  
  components: {
    Header
  },
 
  created(){
    this.getData()
  },
  methods:{
    getData(){
      let params={
        id:this.key
      }
      this.$store
        .dispatch('transation/orders',{params:params})
        .then((res) => {
          this.data =res.order
          this.type=res.type
          this.activities=res.order.history
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.success-box{
  // min-height: 100vh;
  >.flex-box{
    background: #ffffff;
    padding:2.5%;
    flex-shrink: 0;
    width: 90%;
    margin-top:2.5%;
   
    .right-box{
      position: relative;
      width: 95%;
      .right-title{
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
      .sub{
        font-size: .8rem;
        color: #464646;
        line-height: 2;
      }
      .flex-box{
        justify-content: space-between;
        width: 100%;
        line-height: 2;
        color:#464646;

      }
      .top-row{
        margin-top: .5rem;
        // border-bottom: 1px solid #e4e4e4;
      }
    }
    
  }
}
.schedule-box{
  // margin:2.5% 0px;
  margin-top:2.5%;
  padding: 2rem 3rem;
  background: #ffffff;
}
.text-t{
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
.tj{
  position: absolute;
  right: 0px;
  top:5px;
  font-size:.6rem;
  border:1px solid #d10921;
  border-radius:12px;
  padding:2px 6px;
  color: #d10921;
  margin-left:10px
}
</style>