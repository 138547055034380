var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", {
        attrs: { title: "订单详情", back: true, complete: "home" },
      }),
      _c("div", { staticClass: "success-box" }, [
        _c("div", { staticClass: "flex-box box-border" }, [
          _c("div", { staticClass: "right-box" }, [
            _vm.type == "transfer" || _vm.type == "withdraw"
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      padding: "2rem 0px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { "font-size": "2rem", color: "#d10921" },
                      },
                      [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm._f("formatFloat")(
                                _vm.data ? _vm.data.money : "-"
                              )
                            )
                        ),
                      ]
                    ),
                    _c("div", { staticStyle: { "font-size": ".9rem" } }, [
                      _vm._v(_vm._s(_vm.data ? _vm.data.status : "-")),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.type == "withdraw"
              ? _c("div", [
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("收款银行")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(_vm._s(_vm.data ? _vm.data.bankname : "-")),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("收款账号")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(_vm._s(_vm.data ? _vm.data.account : "-")),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("申请时间")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(_vm._s(_vm.data ? _vm.data.addtime : "-")),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("订单号")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(_vm._s(_vm.data ? _vm.data.code : "-")),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.type == "transfer"
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", [_vm._v("转出金额")]),
                      _c("div", { staticClass: "theme-color text-t" }, [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm._f("formatFloat")(
                                _vm.data ? _vm.data.money : "-"
                              )
                            )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", [_vm._v("申请时间")]),
                      _c("div", { staticClass: "theme-color text-t" }, [
                        _vm._v(
                          _vm._s(_vm.data ? _vm.formatDate(_vm.data.time) : "-")
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", [_vm._v("订单号")]),
                      _c("div", { staticClass: "theme-color text-t" }, [
                        _vm._v(_vm._s(_vm.data ? _vm.data.code : "-")),
                      ]),
                    ]),
                    _c("el-divider"),
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", [_vm._v("收款方ID")]),
                      _c("div", { staticClass: "theme-color text-t" }, [
                        _vm._v(_vm._s(_vm.data ? _vm.data.usercode : "-")),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", [_vm._v("收款方姓名")]),
                      _c("div", { staticClass: "theme-color text-t" }, [
                        _vm._v(_vm._s(_vm.data ? _vm.data.name : "-")),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-box" }, [
                      _c("div", [_vm._v("与对方关系")]),
                      _c("div", { staticClass: "theme-color text-t" }, [
                        _vm._v(_vm._s(_vm.data ? _vm.data.relation : "-")),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.type == "in_installment" || _vm.type == "out_loan"
              ? _c("div", [
                  _c("span", { staticClass: "tj" }, [
                    _vm._v(_vm._s(_vm._f("filterStatus")(_vm.type))),
                  ]),
                  _c("div", { staticClass: "theme-color right-title" }, [
                    _vm._v(_vm._s(_vm.data ? _vm.data.title : "-")),
                  ]),
                  _c("div", { staticClass: "sub" }, [
                    _vm._v("订单号：" + _vm._s(_vm.key)),
                  ]),
                ])
              : _vm._e(),
            _vm.type == "in_installment"
              ? _c("div", [
                  _c("div", { staticClass: "flex-box top-row" }, [
                    _c("div", [_vm._v("预约买入金额")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(
                        "$" +
                          _vm._s(
                            _vm._f("formatFloat")(
                              _vm.data ? _vm.data.money : "-"
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("预期年化收益率")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatFloat")(_vm.data ? _vm.data.rate : "-")
                        ) + "%"
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.type == "out_loan"
              ? _c("div", [
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("提前转出金额")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(
                        "$" +
                          _vm._s(
                            _vm._f("formatFloat")(
                              _vm.data ? _vm.data.money : "-"
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("手续费(1.5%)")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(
                        "$" +
                          _vm._s(
                            _vm._f("formatFloat")(
                              _vm.data ? _vm.data.handlingfee : "-"
                            )
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "flex-box" }, [
                    _c("div", [_vm._v("到账金额")]),
                    _c("div", { staticClass: "theme-color text-t" }, [
                      _vm._v(
                        "$" +
                          _vm._s(
                            _vm._f("formatFloat")(
                              _vm.data ? _vm.data.cash : "-"
                            )
                          )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "schedule-box box-border" },
          [
            _c(
              "el-timeline",
              _vm._l(_vm.activities, function (activity, index) {
                return _c(
                  "el-timeline-item",
                  {
                    key: index,
                    attrs: {
                      icon: activity.confirm ? "el-icon-check" : "",
                      type: activity.type,
                      color: activity.confirm ? "#002D91" : "",
                      size: "large",
                      timestamp: _vm.formatDate(activity.time),
                    },
                  },
                  [_vm._v(" " + _vm._s(activity.comment) + " ")]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }